::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  right: 0;
  width: 6px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 8px;
  width: 12px;
  height: 5px;
}

a {
  text-decoration: none;
  color: #404040;
}