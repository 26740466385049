
.appBarRoot {
    width: "100vw";
    font-family: "Poppins, sans-serif !important";
    height:50px !important;
    /* box-shadow: "0 7px 30px -10px rgba(150,170,180,0.5)" !important */
    box-shadow:rgba(150, 150, 150, 0.2) -1px 4px 14px 0px   !important
  }
  
  .toolbar {
    display: flex;
    justify-content: space-between;
  }
  
  .logo {
    height: 30px;
  }
  
  .menuContainer {
    display: flex;
    gap: 20px;
    margin: 0 20px;
    
  }
  
  .menuItem {
    cursor: pointer;
    font-family: "Poppins, sans-serif";
    font-size: 14px;
  }
  
  .searchField {
    width: 200px;
    transition: width 0.3s ease-in-out;
  }
  
  .searchInput {
    font-size: 14px !important;
    padding: 10px !important;
    font-family: "Poppins, sans-serif !important" ;

  }
  
  .searchLabel,
  .searchFormLabel {
    font-size: 14px;
  }
  
  .searchPlaceholder {
    font-size: 12px;
  }
  
  .languageSelect {
    margin-right: 2px;
    font-family: "Poppins, sans-serif !important";
    padding-left: 10px;
    font-size: 14px !important;
    margin-top: 10px !important;

  }
  
  .iconButton {
    color: inherit;
    font-size:20px !important
  }
  
  .drawerListItem {
    padding: 8px;
  }
  
